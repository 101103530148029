var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-detail-media-gallery"},[_c('div',{staticClass:"gallery-grid-container tablet"},_vm._l((_vm.media),function(item,index){return _c('div',{key:index,class:{
        '-big-image':
          index === 0 ||
          (_vm.media.length % 2 === 0 && index === _vm.media.length - 1),
      }},[(item.imageUrl)?_c('div',{staticClass:"image-container",on:{"click":function($event){return _vm.onClickMedia(index)}}},[_c('img',{staticClass:"image",attrs:{"src":item.imageUrl,"alt":item.alt,"loading":"lazy"}}),_vm._m(0,true)]):_vm._e()])}),0),_c('div',{staticClass:"gallery-swiper-container mobile"},[_c('swiper',{ref:"swiperMediaGallery",staticClass:"swiper",attrs:{"options":_vm.swiperOptions},on:{"slideChange":_vm.onSwiperSlideChange}},_vm._l((_vm.media),function(item,index){return _c('swiper-slide',{key:item.id,class:[
          `slide-gallery-${index}`,
          'swiper-slide-container',
        ]},[(item.imageUrl)?_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"image",attrs:{"src":item.imageUrl,"alt":item.alt,"loading":"lazy"}})]):_vm._e()])}),1),_c('div',{staticClass:"gallery-navigator"},[_vm._m(1),_c('span',{staticClass:"label-l-regular"},[_vm._v(_vm._s(_vm.activeIndex)+"/"+_vm._s(_vm.media.length))]),_vm._m(2)])],1),_c('PDPGalleryModal',{ref:"PDPGalleryModal",attrs:{"media":_vm.media}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"zoom-image"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icon/studio7/magnifying-glass-zoom.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-gallery-button-prev left"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icon/studio7/chevron-left.svg"),"alt":"prev"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-gallery-button-next right"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icon/studio7/chevron-right.svg"),"alt":"next"}})])
}]

export { render, staticRenderFns }