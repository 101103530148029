<template>
  <div class="product-detail-media-gallery">
    <!-- Desktop -->
    <div class="gallery-grid-container tablet">
      <!-- Display big image if index is 0 or last image shows only 1 image -->
      <div
        v-for="(item, index) in media"
        :key="index"
        :class="{
          '-big-image':
            index === 0 ||
            (media.length % 2 === 0 && index === media.length - 1),
        }"
      >
        <div
          v-if="item.imageUrl"
          class="image-container"
          @click="onClickMedia(index)"
        >
          <img
            class="image"
            :src="item.imageUrl"
            :alt="item.alt"
            loading="lazy"
          />
          <span class="zoom-image">
            <img
              class="icon"
              src="@/assets/icon/studio7/magnifying-glass-zoom.svg"
            />
          </span>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="gallery-swiper-container mobile">
      <swiper
        ref="swiperMediaGallery"
        class="swiper"
        :options="swiperOptions"
        @slideChange="onSwiperSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in media"
          :key="item.id"
          :class="[
            `slide-gallery-${index}`,
            'swiper-slide-container',
          ]"
        >
          <div v-if="item.imageUrl" class="image-container">
            <img
              class="image"
              :src="item.imageUrl"
              :alt="item.alt"
              loading="lazy"
            />
          </div>
        </swiper-slide>
      </swiper>
      <div class="gallery-navigator">
        <div class="swiper-gallery-button-prev left">
          <img
            src="@/assets/icon/studio7/chevron-left.svg"
            alt="prev"
            class=" icon"
          />
        </div>
        <span class="label-l-regular"
          >{{ activeIndex }}/{{ media.length }}</span
        >
        <div class="swiper-gallery-button-next right">
          <img
            src="@/assets/icon/studio7/chevron-right.svg"
            alt="next"
            class=" icon"
          />
        </div>
      </div>
    </div>

    <PDPGalleryModal ref="PDPGalleryModal" :media="media" />
  </div>
</template>

<script>
export default {
  name: 'ProductDetailMediaGallery',
  components: {
    PDPGalleryModal: () =>
      import('@/components/studio7/PDPGalleryModal.vue'),
  },

  props: {
    media: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 1,
        grabCursor: true,
        threshold: 16,
        navigation: {
          nextEl: '.swiper-gallery-button-next',
          prevEl: '.swiper-gallery-button-prev',
        },
      },

      isDesktop: true,
      activeVideoRef: null,
      activeIndex: 1,
    }
  },

  computed: {
    swiper() {
      return this.$refs.swiperMediaGallery?.$swiper ?? null
    },
  },

  methods: {
    onClickMedia(refActiveIndex) {
      this.$refs.PDPGalleryModal.openModal(refActiveIndex)
    },
    onSwiperSlideChange() {
      this.activeIndex = this.swiper ? this.swiper.realIndex + 1 : 1
    },
  },
}
</script>

<style lang="stylus" scoped>
.product-detail-media-gallery {
    width: 100%;
    max-width: 100dvw;
    margin-bottom: 16px;
}

.gallery-grid-container {
    display: none;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 32px 16px;

    @media screen and (min-width: 768px) {
            display: grid;
    }
}

.-big-image {
    grid-column: 1 / -1;
}

.placeholder-image {
    height: 200px;
}

.gallery-swiper-container {
    position: relative;
    width: 100%;
    max-width: 100dvw;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.gallery-navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 56px;

    .icon {
        width: 16px;
        height: 16px;

        color: $color-theme-grey-3;
    }
}

.gallery-navigator-arrow ::v-deep {
    position: static;
    display: block !important;
}

.image-container {
    min-height: 200px;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    cursor: default;

    @media screen and (min-width: 768px) {
        cursor: pointer;
    }

    &:hover + .zoom-image {
        opacity: 1;
    }
}

.zoom-image {
    height: 30px;
    width: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    opacity: 0;
    position: absolute;
    top: 8px;
    left: 8px;

    background-color: #fff;
    border: 1.3px solid rgba(#121212, 0.1);
    border-radius: 50%;

    transition: all 0.1s;

    &:hover {
        opacity: 1;
    }

    .icon {
        height: 14px;
        width: 12px;
    }
}

@include desktop {
    .product-detail-media-gallery {
        margin-bottom: unset;
    }
}

.video-player-container {
    height: 100%;
}

.swiper-slide-container {
    align-self: center;
}
</style>
